<template>
  <v-select
    autocomplete
    label="Funcionario"
    placeholder="Selecione..."
    :rules="[() => !!codFuncionario || 'Campo obrigatório']"
    v-model="codFuncionario"
    :items="funcionarios"
    item-value="id"
    @change="onChangeEvent"
    @input="setSelected"
    item-text="nome"
    required
  ></v-select>
</template>
<script>
import { serverBus } from "../../main"
export default {
  data: () => ({
    codFuncionario: null
  }),
  props: {
    funcionarios: Array,
    onChangeEvent: Function,
    idFuncionario: Number
  },
  created() {
    this.codFuncionario = this.idFuncionario
  },
  methods: {
    setSelected(value) {
      this.codFuncionario = value
      serverBus.$emit("funcionarioSelected", value)
    }
  }
}
</script>
