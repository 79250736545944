<template>
  <div>
    <v-data-table
      :items="pracas"
      :expand="expandido"
      item-key="idPraca"
      hide-actions
    >
      <template v-slot:items="props">
        <tr @click="props.expanded = !props.expanded">
          <td class="subheading text-xs-left">{{ props.item.nomePraca }}</td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <pagamentos-praca :notas-retorno="props.item.notasRetorno"></pagamentos-praca>
      </template>
    </v-data-table>
  </div>
</template>

<script>


import pagamentosPraca from "@/components/acertoPromotorVendas/pagamentosPraca";

export default {
  components: {
    pagamentosPraca
  },
  data() {
    return {
      expandido: false
    }
  },
  props: {
    pracas: {
      type: Array,
      required: true
    }
  },
  computed: {

  }
}
</script>
