<template>
    <div>
      <v-data-table
        :items="itensPagamento"
        item-key="idVendedorPagamentoItem"
        :headers="cabecalhoTabela"
        hide-actions
      >
        <template v-slot:items="props">
          <tr>
            <td class="subheading text-xs-left">{{ props.item.nomeVendedor }}</td>
            <td class="subheading text-xs-left">{{ props.item.valorPago }}</td>
            <td class="subheading text-xs-left">{{ props.item.descricaoFormaPagamento }}</td>
            <td class="subheading text-xs-left">{{ props.item.dataPagamento }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
</template>

<script>
export default {
  props: {
    notasRetorno: {
      type: Array,
      required: true
    }
  },
  computed: {
    notasRetornoModelView: function() {
      return this.notasRetorno || []
    },
    itensPagamento: function() {
      return this.notasRetornoModelView.flatMap(n => n.itens)
    },
    cabecalhoTabela: function() {
      return [
        { align: "left", sortable: false, text: "Vendedor" },
        { align: "left", sortable: false, text: "Valor pago" },
        { align: "left", sortable: false, text: "Forma pagamento" },
        { align: "left", sortable: false, text: "Data pagamento" }
      ]
    }
  }
}
</script>
