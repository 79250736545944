import { ServicoFuncionario } from "@/servicos/servicoFuncionario"
import { ServicoAcertoPromotorVendas } from "@/servicos/servicoAcertoPromotorVendas"

export default {
  async consultarDadosAberturaTela() {
    let servicoFuncionario = new ServicoFuncionario()
    const funcionarios = await servicoFuncionario.buscarFuncionario().then(r => r.data)
    return new AcertoPromotorVendasModelView(funcionarios)
  },
  async consultarInformacoesParaAcertoDaSemanaParaFuncionario(idFuncionario) {
    return new ServicoAcertoPromotorVendas().buscarInformacoesParaAcertoDaSemanaParaFuncionario(idFuncionario)
  }
}

export class AcertoPromotorVendasModelView {
  funcionarios
  idFuncionario = null
  dadosAcertos = null
  descricao = ""
  despesas = 0

  constructor(funcionarios) {
    this.funcionarios = funcionarios
  }
}
