<template>
  <div id="pageAcertoPromotorVendas">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap>
        <v-flex lg12 sm12 xs12>
          <form-acerto-promotor-vendas></form-acerto-promotor-vendas>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import formAcertoPromotorVendas from "@/components/acertoPromotorVendas/formAcertoPromotorVendas"

export default {
  components: {
    formAcertoPromotorVendas
  }
}
</script>
