import axios from "axios"

export class ServicoAcertoPromotorVendas {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/AcertoPromotorVendas`
  }

  buscarInformacoesParaAcertoDaSemanaParaFuncionario(idFuncionario) {
    return axios.get(`${this.urlServico}/ConsultarAcertoPorFuncionario/${idFuncionario}`)
  }
}