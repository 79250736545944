<template>
  <v-form v-model="valid" ref="form">
    <div :v-if="isModelViewCarregado">
      <v-flex xs4 sm4>
        <select-funcionario-lazy
          :funcionarios="funcionarios"
          :on-change-event="consultarDadosAcertoFuncionario"
          :id-funcionario="idFuncionario"
        >
        </select-funcionario-lazy>
      </v-flex>

      <total-cobrado-promotor-vendas v-if="isDadosAcertoCarregado" :dados-acertos="dadosAcertos">
      </total-cobrado-promotor-vendas>

      <v-layout v-if="isDadosAcertoCarregado">
        <v-flex xs2 sm2>
          <v-text-field
            type="number"
            prefix="R$"
            v-model="modelView.despesas"
            :rules="despesaRules"
            label="Despesas"
            required
          >
          </v-text-field>
        </v-flex>
      </v-layout>

      <v-layout v-if="isDadosAcertoCarregado">
        <v-flex xs12 sm12>
          <v-divider></v-divider>
          <v-layout row wrap class="mt-1">
            <v-flex xs4>
              <span class="subheading">Total: {{ total }}</span>
            </v-flex>
            <v-flex xs4>
              <span class="subheading">Resta: {{ resta }}</span>
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
        </v-flex>
      </v-layout>

      <pagamentos-pracas v-if="isDadosAcertoCarregado" :pracas="pracas"></pagamentos-pracas>

      <div class="form-btn" v-if="isDadosAcertoCarregado">
        <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      </div>
    </div>
  </v-form>
</template>
<script>
import action from "../../store/module/acertoPromotorVendas/actions"
import selectFuncionarioLazy from "@/components/funcionario/selectFuncionarioLazy"
import totalCobradoPromotorVendas from "@/components/acertoPromotorVendas/totalCobradoPromotorVendas"
import pagamentosPracas from "@/components/acertoPromotorVendas/pagamentosPracas"
import calculos from "@/components/acertoPromotorVendas/acertoPromotorVendasCalculos"

export default {
  components: {
    selectFuncionarioLazy,
    totalCobradoPromotorVendas,
    pagamentosPracas
  },
  data: () => ({
    modelView: null,
    despesaRules: [
      v => !!v || "Despesa é obrigatório e deve ser um número válido",
      v => v >= 0 || "Despesa deve igual ou maior que zero"
    ],
    valid: true
  }),
  methods: {
    submit() {},
    clear() {
      this.$validator.reset()
    },

    async consultarDadosAcertoFuncionario(idFuncionario) {
      this.modelView.idFuncionario = idFuncionario
      this.modelView.dadosAcertos = await action
        .consultarInformacoesParaAcertoDaSemanaParaFuncionario(idFuncionario)
        .then(res => res.data)
    }
  },
  async created() {
    try {
      this.modelView = await action.consultarDadosAberturaTela()
    } catch (e) {
      alert("Não foi possível carregar os dados. Tente recarregar a pagina ou contate o administrador.")
    }
  },
  computed: {
    idFuncionario: function() {
      return this.isModelViewCarregado ? this.modelView.id : null
    },
    funcionarios: function() {
      return this.isModelViewCarregado ? this.modelView.funcionarios : []
    },
    isModelViewCarregado: function() {
      return this.modelView != null
    },
    isDadosAcertoCarregado: function() {
      return this.modelView && this.modelView.dadosAcertos != null
    },
    dadosAcertos: function() {
      return this.modelView != null ? this.modelView.dadosAcertos : null
    },
    pracas: function() {
      return this.isDadosAcertoCarregado ? this.modelView.dadosAcertos.pracas : []
    },
    despesas: function() {
      return this.isModelViewCarregado ? this.modelView.despesas : 0
    },
    total: function() {
      return this.isDadosAcertoCarregado ? calculos.obterTotalValoresPagos(this.pracas, this.despesas) : 0
    },
    resta: function() {
      return this.total - this.modelView.dadosAcertos.valorTaxa
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
