import redutores from "@/util/redutores"
import parsers from "@/util/parseNumbers"

export default {
  obterTotalValoresPagos(pagamentos, despesas) {
    const intDespesas = parsers.parseNumberOrZero(despesas)
    return (
      this.obterValoresPagos(pagamentos)
        .map(p => p.valor)
        .reduce(redutores.somarTodos, 0) + intDespesas
    )
  },
  obterValoresPagos(pagamentos) {
    return this.obterFormasPagamentos(pagamentos).map(fp => ({
      descricao: fp.descricao,
      valor: this.calcularTotalPagoPorTipoPagamento(fp.idFormaPagamento, pagamentos)
    }))
  },
  obterFormasPagamentos(pagamentos) {
    const itens = pagamentos.flatMap(p => p.notasRetorno).flatMap(n => n.itens)
    const formasPagamento = Array.from(new Set(itens.map(i => i.idFormaPagamento)))
    return formasPagamento.map(idFormaPagamento => ({
      descricao: itens.find(item => item.idFormaPagamento === idFormaPagamento).descricaoFormaPagamento,
      idFormaPagamento
    }))
  },
  calcularTotalPagoPorTipoPagamento(idFormaPagamento, pagamentos) {
    return pagamentos
      .flatMap(p => p.notasRetorno)
      .flatMap(n => n.itens)
      .filter(i => i.idFormaPagamento === idFormaPagamento)
      .map(i => i.valorPago)
      .reduce(redutores.somarTodos, 0)
  }
}
