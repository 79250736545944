<template>
  <v-container v-if="isDadosAcertosCarregados" id="grid" fluid grid-list-sm class="ma-0 pa-0" tag="section">
    <v-layout>
      <v-flex xs12 sm12>
        <h3 class="heading mb-3">Praças: {{ nomesPracas }}</h3>
        <v-divider></v-divider>
        <v-layout row wrap class="mt-1">
          <v-flex v-for="item in totalizadores" :key="item.descricao" xs4>
            <span class="subheading">{{ item.descricao }}: {{ item.valor }}</span>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row wrap class="mt-1">
          <v-flex v-for="item in valoresCalculados" :key="item.descricao" xs12>
            <span class="subheading">{{ item.descricao }}: {{ item.valor }}</span>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import calculos from "@/components/acertoPromotorVendas/acertoPromotorVendasCalculos"

export default {
  props: {
    dadosAcertos: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDadosAcertosCarregados: function() {
      return this.dadosAcertos != null
    },
    totalizadores: function() {
      if (this.isDadosAcertosCarregados) {
        return [
          { descricao: "Notas Cobradas", valor: this.dadosAcertos.valorPagamentoNotasCobradas },
          { descricao: "Notas Parciais", valor: this.dadosAcertos.valorPagamentoNotasParciais },
          { descricao: "Pendencia Total", valor: this.dadosAcertos.valorPagamentoPendenciaTotal },
          { descricao: "Pendencia Parcial", valor: this.dadosAcertos.valorPagamentoPendenciaParcial },
          { descricao: `Quantidade de notas`, valor: this.dadosAcertos.quantidadeNotas },
          { descricao: `Média Notas`, valor: this.dadosAcertos.valorPagamentoMedioPorNotaRetorno },
          { descricao: `Kit s/ Pegar`, valor: this.dadosAcertos.kitsSemPegar }
        ]
      }
      return []
    },
    valoresCalculados: function() {
      if (this.isDadosAcertosCarregados) {
        return [
          { descricao: "Sub Total", valor: this.dadosAcertos.subTotal },
          { descricao: `${this.percentualTaxa}%`, valor: this.dadosAcertos.valorTaxa },
          { descricao: "Total", valor: this.dadosAcertos.totalVendidoMenosTaxa },
          { descricao: "Comissão", valor: this.dadosAcertos.valorComissao },
          { descricao: `Total Liquido + ${this.percentualTaxa}%`, valor: this.dadosAcertos.totalLiquidoComTaxa },
          ...calculos.obterValoresPagos(this.dadosAcertos.pracas)
        ]
      }
      return []
    },
    percentualTaxa: function() {
      return this.dadosAcertos.percentualTaxa * 100
    },
    nomesPracas: function() {
      return this.isDadosAcertosCarregados ? this.dadosAcertos.pracas.map(p => p.nomePraca).join(", ") : ""
    }
  }
}
</script>
